import React from 'react';
import './Layout.css'; // Assuming you have or will create some basic styles for layout
import { useNavigate } from 'react-router-dom';

const Layout = ({ children }) => {

  const navigate = useNavigate();
  const redirectToHome = () => {
    navigate('/');
  };

  return (
    <div>
  <header style={{ display: 'flex', alignItems: 'center', height: '10px' , minHeight:"12svh" }}>
    <img src="/jgmascotheader2.webp" alt="JobGoblin Mascot" onClick={redirectToHome} style={{ height: '100%', marginRight: '10px',marginLeft: '10px' }}/>
    <h1 style={{ margin: 0 }} onClick={redirectToHome}>JobGoblin</h1>
  </header>
      <main style={{minHeight:"80svh"}}>{children}</main>
      <footer style={{minHeight:"8svh"}}>Footer content here &copy; 2024</footer>
    </div>
  );
};

export default Layout;
