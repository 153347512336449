import './HomePage.css';
import { useNavigate } from 'react-router-dom';

const HomePage = ({jobList,getAllJobs}) => {
  const navigate = useNavigate();


  return (
    <div>
      {/* <JobFilter onFilterChange={handleFilterChange} /> */}
      <div className="jobs-grid">
        {jobList.map(job => (
          <div key={job._id} onClick={() => navigate(`/job/${job._id}`)} className="job-card">
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <div className="job-company" style={{textSizeAdjust:"20px",fontWeight:"bold",color:"#305926"}}>{job.Company}</div>
                <div className="job-views" style={{color:"#808080"}}>
                  <img src={job && job.views>0?"eye.svg":"closed-eye.svg"} alt='eye open'/>{(job && job.views>0)?"    "+job.views:""}
                </div>
                </div>
              <div className="job-details">
                <div className="job-title">{job.JobTitle}</div>
                {job.Location && <div className="job-location">{job.Location}</div>}
                {job.position && <div className="job-position">Position: {job.position}</div>}
                <br/>
                {job.JobDescription && <div className="job-description">Description: {job.JobDescription.substring(0,80)}...</div>}
              </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage;
