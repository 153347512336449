import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useParams } from 'react-router-dom';

const JobDetailsPage = ({jobs,setJobs}) => {
  const { id } = useParams();
  const [job, setJob] = useState(null);

  useEffect(() => {

      setJob(jobs.find((job) => job._id === id));
      console.log("job : ",job);
      axios.put(`https://jgfams.azurewebsites.net/api/incView/${id}?code=s8-Whf9qXOQKN5MHOR7xc9q9XEc6ISwSW8g4BZuaXyhAAzFuTnS8Nw==`, 
        {method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          mode: 'no-cors'
        }
      )
      .then(response => {
        console.log("views updated successfully!")
        setJobs((currentItems) =>
          currentItems.map((item) =>
            {
              if(item._id === id){ 
                return { ...item, views: (item.views || 0) + 1 }
              } 
              else{
                return item
              }
            }
          )
        );
        
      })
      .catch(error => {
        console.log(error)
      })
  }, []);

  if (!job) return <div>Loading...</div>;

  return (
    <div>
      <h2>{job.JobTitle}</h2>
      <p>{job.JobDescription}</p>
    </div>
  );
};

export default JobDetailsPage;
