import React,{useState,useEffect} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import JobDetailsPage from "./JobDetailsPage";
import Layout from "./Layout";
import axios from "axios";

function App() {
  
  const [jobs, setJobs] = useState([])
  const getAllJobs = () => {
    axios.get('https://jgfams.azurewebsites.net/api/getJobs?code=WKaDhK9A_8E4HYhJMDcQ2wc14jybmv4ldbO6R1Mjipi4AzFugBYQIA==', 
      {method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      mode: 'no-cors'
    }
    )
    .then(response => {
      setJobs(response.data)
      console.log("jobs 1 : ",response.data)
    })
    .catch(error => {
      console.log(error)
    })
  }
  useEffect(()=>{
    getAllJobs()
  },[])


  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage jobList={jobs} getAllJobs={getAllJobs} />} />
          <Route path="/job/:id" element={<JobDetailsPage jobs={jobs} setJobs={setJobs}/>} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
